import React, { useEffect, useState } from 'react';
import './CommoditiesWrapper.scss';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { appDataActions, appDataSelector } from '../../../../../features/appDataSlice';
import { bookingActions, bookingSelector, ImperialMetric } from '../../../../../features/bookingSlice';
import Commodity from './commodity/Commodity';
import { Icon } from '../../../../../ui-components/UiComponents';
import { getRule } from "../../../../../utilities/RulesEngineUtilities";

const CommoditiesWrapper: React.FC<{customRules: any}> = ({customRules}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const shipmentCommodities = booking.customsDetails.commodities;
  const shipmentPieces = booking.pieces;
  const value = +booking.totalShipmentValue;
  const appData = useAppSelector(appDataSelector);

  const isRequired = getRule(appData.customRules, 'commodityExemption') === false;


  const handleAddCommodity = () => {
    dispatch(bookingActions.addCommodity(
      {
        commodityCode: '',
        description: '',
        weight: {
          value: '',
          unit: booking.imperialMetric === ImperialMetric.IMPERIAL
          ? 'LB'
          : 'KG'
        },
        quantity: {
          value: '',
          unit: 'PCS'
        },
        unitPrice: {
          value: '',
          unit: 'USD'
        }
      },
    ));
  }

  const copyCommodity = (commodity: any, index: number) => {
    const commodityCopy = {...commodity};
    const commoditiesCopy = [...shipmentCommodities];
    commoditiesCopy.splice(index + 1, 0, commodityCopy);
    dispatch(bookingActions.setCommodities(commoditiesCopy));
  }

  const deleteCommodity = (index: number) => {
    if (shipmentCommodities.length > 1) {
      const commoditiesCopy = [...shipmentCommodities];
      commoditiesCopy.splice(index, 1);
      dispatch(bookingActions.setCommodities(commoditiesCopy));
    }
  }

  const [listWeight, setListWeight] = useState(0);
  const [listValue, setListValue] = useState(0);
  const [weight, setWeight] = useState(0);

  useEffect(() => {
    let listWeight = 0;
    let listValue = 0;
    let weight = 0;

    for (const commodity of shipmentCommodities) {
      if (commodity.weight.value) {
        listWeight += +commodity.weight.value;
      }

      listValue += +commodity.quantity.value * +commodity.unitPrice.value;
    }

    for (const piece of shipmentPieces) {
      weight += +piece.weight;
    }

    setListWeight(+listWeight.toFixed(2));
    setListValue(+listValue.toFixed(2));
    setWeight(+weight.toFixed(2));

  }, [shipmentCommodities])

  useEffect(() => {
    if (
      isRequired
      && (
        listWeight !== weight || +listValue.toFixed(2) !== value
      )
    ) {
      dispatch(appDataActions.setCommodityWeightValueError(true));
    } else {
      dispatch(appDataActions.setCommodityWeightValueError(false));
    }
  }, [listWeight, listValue])

  return (
    <div className="commodities-wrapper">
      {booking.customsDetails.commodities.map((commodity, index) => {
        return (
          <div key={index}>
            <Commodity
              commodity={commodity}
              index={index}
              isRequired={isRequired}
              onCopy={() => copyCommodity(commodity, index)}
              customRules={customRules}
              onDelete={() => deleteCommodity(index)}
            ></Commodity>
          </div>
        )
      })}

      <div className="commodities-wrapper__sub-commodities-container">
        <div
          className="add-commodity-icon"
          onClick={handleAddCommodity}
        >
          <Icon name="Close" color="var(--primary)" />Add Commodity
        </div>

        <div className="commodities-wrapper__sub-commodities-container__totals-grid">
          <p><span>Weight:</span><br />{weight.toFixed(2)}{booking.imperialMetric === ImperialMetric.IMPERIAL ? "lb" : "kg"}</p>
          <p><span>Value:</span><br />{booking.preferredCurrency.symbol}{value.toFixed(2)}</p>
          <p className={
            listWeight === weight || !isRequired
              ? 'commodity-total'
              : 'commodity-total-red'
            }><span>List Weight:</span><br />{listWeight.toFixed(2)}{booking.imperialMetric === ImperialMetric.IMPERIAL ? "lb" : "kg"}</p>
          <p className={
            +listValue === value || !isRequired
              ? 'commodity-total'
              : 'commodity-total-red'
            }><span>List Total Value:</span><br />{booking.preferredCurrency.symbol}{listValue.toFixed(2)}</p>
        </div>

      </div>
    </div>
  )
}

export default CommoditiesWrapper;