import { Booking, bookingActions, Country, ImperialMetric, QuoteObj, ShipmentType } from "../../../features/bookingSlice";
import { customerActions, CustomerDetails } from "../../../features/customerSlice";
import { getCountries, getCountryInfo, retrieveQuotesCall } from "../../../utilities/APIUtilities";
import dayjs from 'dayjs';
import { getTradeRoute, TradeRoute } from "../../../utilities/RulesEngineUtilities";
import { AppData, appDataActions } from "../../../features/appDataSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import { getCurrencyConversionRate } from "../../../utilities/APIUtilities";
import { formatCounties, getCarrierName } from "../../../utilities/HelperUtilities";
import { Template } from "../../../utilities/TemplateHandler";

const getPieceWeight = (booking: Booking, piece: any) => {
  let weight = +piece.weight;
  if (booking.shipmentType === ShipmentType.ENVELOPE) {
    weight = booking.imperialMetric === ImperialMetric.IMPERIAL
      ? 0.5
      : 0.2
    }
  return weight;
}

export const getEnvelopeDimensions = (booking: Booking, customer: CustomerDetails) => {
  let weight = booking.imperialMetric === ImperialMetric.IMPERIAL
  ? '0.5'
  : '0.2';
  if (customer.countryOfResidence.value === "AU") {
    weight = booking.imperialMetric === ImperialMetric.IMPERIAL
      ? '1.1'
      : '0.5';
  }
  const length = booking.imperialMetric === ImperialMetric.IMPERIAL
  ? '12'
  : '30';
  const width = booking.imperialMetric === ImperialMetric.IMPERIAL
  ? '9'
  : '22';
  const height = booking.imperialMetric === ImperialMetric.IMPERIAL
  ? '1'
  : '2.4';

  return {weight, length, width, height};
}

export const mapParcels = (booking: Booking, customer: CustomerDetails) => {
  const shipmentType = booking.shipmentType;

  const mappedParcels = booking.pieces.map((piece) => {
    if (booking.shipmentType === ShipmentType.ENVELOPE) {
      piece = getEnvelopeDimensions(booking, customer);
    }
    const mapDimensions = +piece.length > 0 && +piece.width > 0 && +piece.height > 0;
    return {
      dimensions: !mapDimensions
        ? null
        : {
        length: +piece.length,
        width: +piece.width,
        height: +piece.height,
        unit : booking.imperialMetric === ImperialMetric.IMPERIAL ? 'IN' : 'CM'
      },
      weight : {
        value : getPieceWeight(booking, piece),
        unit : booking.imperialMetric === ImperialMetric.IMPERIAL ? 'LB' : 'KG'
      },
      description: ''
    }
  });

  return shipmentType === ShipmentType.ENVELOPE ? [mappedParcels[0]] : mappedParcels;
}

const getConversionRate = async (booking: Booking, customer: CustomerDetails, currencyErrorFn: any, appData: AppData, dispatch: any) => {
  const apiConfig = appData.apiConfig;
  if (customer.countryOfResidence.currencyCode) {
    // if customer countryOfResidence has a valid currency code, use that data
    if (booking.preferredCurrency.value !== customer.countryOfResidence.currencyCode) {
      // if customer has selected currency other than their home currency, return conversion rate
      try {
        // call currency conversion service
        currencyErrorFn(false);
        return getCurrencyConversionRate(apiConfig, booking.preferredCurrency.value, customer.countryOfResidence.currencyCode);
      } catch (error) {
        currencyErrorFn(true);
        return false;
      }
    } else {
      // if customer has not selected a currency different from their home currency, do not set the currencyError to true
      // this is becaue we simply want to return the base shipmentValue without converting it
      currencyErrorFn(false)
      return false;
    }
  } else {
    // if the customer countryOfResidence does not have a valid currencyCode, use data from the appData slice
    try {
      currencyErrorFn(false);
      const homeCountry = appData.countries.filter(country => country.value === customer.customerCountryCode)[0];
      await dispatch(customerActions.updateCountryOfResidenceCurrency(homeCountry.currencyCode));
      return getCurrencyConversionRate(apiConfig, booking.preferredCurrency.value, homeCountry.currencyCode);
    } catch (error) {
      currencyErrorFn(true);
      return false;
    }
  }
}

const calcShipmentValue = async(booking: Booking, customer: CustomerDetails, currencyErrorFn: any, appData: AppData, dispatch: any) => {
  const conversionRate = await getConversionRate(booking, customer, currencyErrorFn, appData, dispatch);
  if (conversionRate) {
    return (+booking.totalShipmentValue * conversionRate).toFixed(2);
  } else {
    return +booking.totalShipmentValue;
  }
}

export const retrieveQuotes = async(
  booking: Booking,
  dispatch: any,
  bookingActions: any,
  customer: CustomerDetails,
  setCurrencyError: any,
  appData: AppData,
  originState?: string
) => {
  let currencyError = false;
  const currencyErrorFn = (value: boolean) => {
    if (value) {
      currencyError = true;
      setCurrencyError(true);
    } else {
      setCurrencyError(false);
    }
  };

  const requestBody = {
    shipmentDateTime: dayjs(booking.readyDate).format('YYYY-MM-DD'),
    customerAccountNumber: customer.creditCheck.tmffPartyId,
    shipmentType: booking.shipmentType,
    declaredValue: await calcShipmentValue(booking, customer, currencyErrorFn, appData, dispatch),
    declaredValueCurrency: customer.countryOfResidence.currencyCode,
    preferredCurrency: booking.preferredCurrency.value,
    senderDetails: {
      address: {
        countryCode: booking.origin.value,
        city: booking.originCityTown,
        postalCode: booking.originPostalCode,
        residential: booking.originResidential,
        stateOrCounty: originState ? originState : null
      }
    },
    deliveryDetails: {
      address: {
        countryCode: booking.destination.value,
        city: booking.destinationCityTown,
        postalCode: booking.destinationPostalCode,
        residential: booking.destinationResidential
      }
    },
    parcels: mapParcels(booking, customer),
    logonEmail: customer.logonEmail
  };

  if (currencyError) {
    return;
  }

  dispatch(bookingActions.updateField({
    field: 'mappedPieces',
    value: requestBody.parcels
  }))

  let quoteResponse: any = await retrieveQuotesCall(requestBody, appData.apiConfig);

  if (customer.countryOfResidence.value === 'GB' && booking.origin.value === 'NO') {
    quoteResponse = quoteResponse.filter((quote: QuoteObj) => {
      return getCarrierName(quote.quoteId) !== "fedex"
    })
  }

  if (typeof(quoteResponse) === 'string') {
    return quoteResponse;
  }

  if (quoteResponse?.length === 0) {
    return 'Sorry, there are no quotes that match your entered criteria'
  }

  return quoteResponse;
}

export const storeQuotes = (quotes: QuoteObj[] | undefined, dispatch: any) => {
  dispatch(bookingActions.updateField({field: 'quotes', value: quotes}));
}

export const getTotalWeight = (pieces: any) => {
  let totalShipmentWeight = 0;
  for (const piece of pieces) {
    totalShipmentWeight += +piece.weight;
  }
  return +totalShipmentWeight.toFixed(2);
}

export const checkIfTotalValueIsRequired = (customer: CustomerDetails, booking: Booking) => {
  let required = false;

  if (getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC) {
    if (booking.shipmentType === ShipmentType.NON_DOCS) {
      required = true;
    }

    const isImperial = booking.imperialMetric === ImperialMetric.IMPERIAL;

    if (
      booking.shipmentType === ShipmentType.DOCS
      && (getTotalWeight(booking.pieces) >= (isImperial ? 44 : 20))
    ) {
      required = true;
    }
  }

  return required;
}

export const mapAndSetCountries = async(countries: Country[] | undefined, customer: CustomerDetails, dispatch: any) => {
  if (countries) {
    const sortedCountries = countries.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name)
    })

    const mappedCountries = sortedCountries.map((country: any) => {
      return ({
        title: country.name,
        value: country.code,
        tradeBloc: country.trade_bloc,
        currencyCode: country.currency_code,
        currencySymbol: getSymbolFromCurrency(country.currency_code),
        eTrade_dhl_import: country.eTrade_dhl_import,
        eTrade_dhl_export: country.eTrade_dhl_export,
        eTrade_fdx_import: country.eTrade_fdx_import,
        eTrade_fdx_export: country.eTrade_fdx_export,
        eTrade_ups_export: country.eTrade_ups_export,
        eTrade_ups_import: country.eTrade_ups_import
      })
    })

    const countryOfResidence = await mappedCountries.find((country: any) => {
      return country.value === customer.customerCountryCode
    }) as Country;


    mappedCountries.splice(0, 0, countryOfResidence);
    await dispatch(appDataActions.setCountries(mappedCountries));
    await dispatch(customerActions.updateCountryOfResidence(countryOfResidence));
  }
}

export const setImperialMetricInBooking = (countryOfResidenceCode: string, dispatch: any) => {
  if (countryOfResidenceCode === 'US') {
    dispatch(bookingActions.setImperialMetric(ImperialMetric.IMPERIAL));
  } else {
    dispatch(bookingActions.setImperialMetric(ImperialMetric.METRIC));
  }
}

export const trimCityAndPostalCodes = (booking: Booking) => {
  return {
    ...booking,
    originCityTown: booking.originCityTown.trim(),
    originPostalCode: booking.originPostalCode.trim(),
    destinationCityTown: booking.destinationCityTown.trim(),
    destinationPostalCode: booking.destinationPostalCode.trim(),
    shipperDetails: {
      ...booking.shipperDetails,
      cityTown: booking.originCityTown.trim(),
      postalCode: booking.originPostalCode.trim()
    },
    collectionDetails: {
      ...booking.collectionDetails,
      cityTown: booking.originCityTown.trim(),
      postalCode: booking.originPostalCode.trim()
    },
    consigneeDetails: {
      ...booking.consigneeDetails,
      cityTown: booking.destinationCityTown.trim(),
      postalCode: booking.destinationPostalCode.trim()
    }
  }
}

export const twoPartPostalCodeCountries = [
  'CA', 'CZ', 'GB', 'GG', 'GR', 'JE', 'SE', 'SH', 'SK'
]

export const onFieldChange = (field: string, event: any, dispatch: any) => {
  const fieldObj = {
    field,
    value: event.value
  }
  dispatch(bookingActions.updateField(fieldObj));
}

export const onOriginChange = async(event: any, dispatch: any, apiConfig: any) => {
  const fieldObj = {
    field: 'origin',
    value: event
  }
  dispatch(bookingActions.updateField(fieldObj));
  dispatch(bookingActions.updateField({
    field: 'countryOfOrigin',
    value: event
  }));
  dispatch(bookingActions.updateShipperDetail({
    field: 'country',
    value: event
  }))

  if (event.value === "US" || event.value === "CA") {
    const countryInfo = await getCountryInfo(apiConfig, event.value);

    const formattedCounties = formatCounties(countryInfo.countiesStates);
    dispatch(appDataActions.updateField({
      field: 'shipperCounties',
      value: formattedCounties
    }))
  } else {
    dispatch(bookingActions.updateShipperDetail({
      field: 'countyStateProvince',
      value: ''
    }))
  }
}

export const onOriginCityChange = (event: any, dispatch: any) => {
  onFieldChange('originCityTown', event.target, dispatch);
  dispatch(bookingActions.updateShipperDetail({
    field: 'cityTown',
    value: event.target.value
  }))
  dispatch(bookingActions.updateCollectionDetail({
    field: 'cityTown',
    value: event.target.value
  }))
}

export const onOriginPostalCodeChange = (event: any, dispatch: any) => {
  onFieldChange('originPostalCode', event.target, dispatch);
  dispatch(bookingActions.updateShipperDetail({
    field: 'postalCode',
    value: event.target.value
  }))
  dispatch(bookingActions.updateCollectionDetail({
    field: 'postalCode',
    value: event.target.value
  }))
}

export const onDestinationChange = async(event: any, dispatch: any) => {
  const fieldObj = {
    field: 'destination',
    value: event
  }
  dispatch(bookingActions.updateField(fieldObj));
}

export const onDestinationCityChange = (event: any, dispatch: any) => {
  onFieldChange('destinationCityTown', event.target, dispatch);
  dispatch(bookingActions.updateConsigneeDetail({
    field: 'cityTown',
    value: event.target.value
  }))
}

export const onDestinationPostalCodeChange = (event: any, dispatch: any) => {
  onFieldChange('destinationPostalCode', event.target, dispatch);
  dispatch(bookingActions.updateConsigneeDetail({
    field: 'postalCode',
    value: event.target.value
  }))
}

export const onTradelaneSwitch = (booking: Booking, dispatch: any, apiConfig: any) => {
  const newOrigin = {
    country: booking.destination,
    cityTown: booking.destinationCityTown,
    postalCode: booking.destinationPostalCode,
    residential: booking.destinationResidential
  };
  const newDestination = {
    country: booking.origin,
    cityTown: booking.originCityTown,
    postalCode: booking.originPostalCode,
    residential: booking.originResidential
  };

  onOriginChange(newOrigin.country, dispatch, apiConfig);
  onOriginCityChange({target: {value: newOrigin.cityTown}}, dispatch);
  onOriginPostalCodeChange({target: {value: newOrigin.postalCode}}, dispatch);
  dispatch(bookingActions.updateField({
    field: 'originResidential',
    value: newOrigin.residential
  }))

  onDestinationChange(newDestination.country, dispatch);
  onDestinationCityChange({target: {value: newDestination.cityTown}}, dispatch);
  onDestinationPostalCodeChange({target: {value: newDestination.postalCode}}, dispatch);
  dispatch(bookingActions.updateField({
    field: 'destinationResidential',
    value: newDestination.residential
  }))

  dispatch(bookingActions.resetCollectionAddressDetails());
  dispatch(bookingActions.resetShipperAddressDetails());
  dispatch(bookingActions.resetConsigneeAddressDetails());
  dispatch(bookingActions.resetBrokerAddressDetails());
  dispatch(bookingActions.resetDropInAddressDetails());
}

export const sortTemplates = (templates: Template[]) => {
  let sortedTemplates: any[] = [];

  if (templates.length > 0) {
    const mappedTemplates = templates.map(template => {
      return {
        title: template.name,
        value: template.id
      }
    })
    sortedTemplates = mappedTemplates.sort((a, b) => {
      return a.title.localeCompare(b.title);
    })
  }
  return sortedTemplates;
}

export const checkForChannelIslands = (event: any, variant: 'ORIGIN' | 'DESTINATION', booking: any, dispatch: any, appData: AppData) => {
  const firstTwoChars = event.target.value.slice(0, 2)?.toUpperCase();
  const isGB = variant === 'ORIGIN' ? booking.origin.value === 'GB' : booking.destination.value === 'GB';

  if (isGB && (firstTwoChars === 'JE' || firstTwoChars === 'GY')) {
    const countryCode = firstTwoChars === 'GY' ? 'GG' : 'JE';
    const country = appData.countries.find(el => el.value === countryCode);
    variant === 'ORIGIN' ? onOriginChange(country, dispatch, appData.apiConfig) : onDestinationChange(country, dispatch);
  }
}

export const updateAncillaryCountryInformation = async(
  countryObject: any,
  dispatch: any,
  setIsCountriesSpinning: any,
  apiConfig: any,
  variant: 'ORIGIN' | 'DESTINATION'
) => {
  let tries = 1;
  setIsCountriesSpinning(true);
  let countryInfo: any;

  while (!countryInfo?.postcodeFormat && tries <= 3) {
    tries++;
    countryInfo = await getCountryInfo(apiConfig, countryObject.value);
  }

  let regex;

  if (
    !countryInfo
    || tries >= 3
    || countryInfo.postcodeFormat === 'Country not postcode-format enabled.'
  ) {
    regex = ''
  } else {
    regex = countryInfo.postcodeFormat;
  }

  dispatch(appDataActions.updateField({
    field: variant === 'ORIGIN' ? 'originPostalCodeRegex' : 'destinationPostalCodeRegex',
    value: regex
  }))

  sortCountiesStates(countryInfo, dispatch, variant);

  setIsCountriesSpinning(false);
}

const sortCountiesStates = (countryInfo: any, dispatch: any, variant: 'ORIGIN' | 'DESTINATION') => {
  if (countryInfo?.countiesStates?.length > 0) {
    const formattedCounties = formatCounties(countryInfo.countiesStates);
    variant === 'ORIGIN'
      ? dispatch(appDataActions.setOriginCounties(formattedCounties))
      : dispatch(appDataActions.setDestinationCounties(formattedCounties))
  } else {
    variant === 'ORIGIN'
      ? dispatch(appDataActions.setOriginCounties([]))
      : dispatch(appDataActions.setDestinationCounties([]));
  }
}

export const updateForEnvelope = (booking: Booking, customer: CustomerDetails, dispatch: any) => {
  if (booking.shipmentType === ShipmentType.ENVELOPE) {
    const piece = getEnvelopeDimensions(booking, customer);
    dispatch(bookingActions.setPieces([piece]));
  }
}

export const handleCountries = async(apiConfig: any, customer: CustomerDetails, dispatch: any) => {
  const rawCountryData = await getCountries(apiConfig);
  await mapAndSetCountries(rawCountryData, customer, dispatch);
}

export const onReadyDateChange = (event: number, dispatch: any) => {
  const fieldObj = {
    field: 'readyDate',
    value: event
  }
  dispatch(bookingActions.updateField(fieldObj));
}

export const onCheck = (field: string, dispatch: any, booking: Booking) => {
  dispatch(bookingActions.updateField({
    field,
    value: !(booking as any)[field]
  }))
}

export const handleAddressSearch = (
  event: any,
  variant: 'ORIGIN' | 'DESTINATION',
  dispatch: any,
  booking: Booking,
  setCurrentTimeout: any,
  currentTimeout: any,
  isProduction: boolean,
  originCityRef: any,
  destinationCityRef: any,
  setOriginPostalCodes: any,
  setDestinationPostalCodes: any
) => {
  let countryCode = '';
  if (variant === 'ORIGIN') {
    onOriginCityChange(event, dispatch);
    countryCode = booking.origin.value;
  }
  if (variant === 'DESTINATION') {
    onDestinationCityChange(event, dispatch);
    countryCode = booking.destination.value;
  }

  if (currentTimeout) clearTimeout(currentTimeout);

  const currentValue = event.target.value;
  if (!currentValue || currentValue.length < 3) return false;

  setCurrentTimeout(setTimeout(() => {
    setCurrentTimeout(null);
    const username = process.env.REACT_APP_GEONAMES_ACCOUNT_USERNAME;
    const address = isProduction ? 'secure.geonames.net' : 'secure.geonames.org';
    const url = `https://${address}/postalCodeSearchJSON?countryCode=${countryCode}&postalcode=&maxRows=100&username=${username}&placename=${currentValue}&isReduced=false`;

    fetch(url)
      .then(response => {
        response.json().then(data => {
          const split = response.url.split('&');
          const placenameQuery = split.filter(e => e.includes('placename'))[0];
          const placename = placenameQuery.split('=')[1].replace(/[^a-zA-Z ]/g, '');
          const searchString = variant === 'ORIGIN' ? originCityRef.current?.value.replace(/\s/g, '') : destinationCityRef.current?.value.replace(/\s/g, '');
          const cityHasFocus = variant === 'ORIGIN' ? document.activeElement === originCityRef.current : document.activeElement === destinationCityRef.current;
          const countryCode = variant === 'ORIGIN' ? booking.origin.value : booking.destination.value;
          const is2PartPostalCode = twoPartPostalCodeCountries.includes(countryCode);

          const cleanPostalCodes = data.postalCodes.map((el: any) => {
            let postalCode = '';
            if (countryCode === 'AZ') postalCode = el.postalCode.split(' ').slice(1, 2)[0];
            else if (countryCode === 'LU') postalCode = el.postalCode.slice(2, el.postalCode.length);
            else postalCode = is2PartPostalCode ? el.postalCode.split(' ').slice(0, 2).join(' ') : el.postalCode.split(' ')[0]
            return {
              ...el,
              postalCode
            }
          })
          if (placename === searchString && cityHasFocus) {
            variant === 'ORIGIN' ? setOriginPostalCodes(cleanPostalCodes) : setDestinationPostalCodes(cleanPostalCodes);
          }
      })})
      .catch(error => console.error(error));
  }, 700));
}

export const whiteLabelChecks = (
  isWlChild: boolean | undefined,
  whiteLabel: any,
  tradeRoute: TradeRoute,
  originObj: any,
  destinationObj: any
) => {
  if (!isWlChild) return true;

  const matchesOrigin = checkMatchesWlAddress(whiteLabel.address, originObj)
  const matchesDestination = checkMatchesWlAddress(whiteLabel.address, destinationObj)

  if (tradeRoute === TradeRoute.DOMESTIC) return matchesOrigin || matchesDestination;
  if (tradeRoute === TradeRoute.EXPORT) return matchesOrigin;
  if (tradeRoute === TradeRoute.IMPORT) return matchesDestination;
}

const checkMatchesWlAddress = (whiteLabelAddress: any, address: any) => {
  return (
    whiteLabelAddress.countryCode.toLowerCase() === address.country.value.toLowerCase()
    && whiteLabelAddress.postalCode.toLowerCase() === address.postalCode.toLowerCase()
    && whiteLabelAddress.city.toLowerCase() === address.cityTown.toLowerCase()
  )
}

export const checkWlAddressExists = (whiteLabel: any) => {
  let showError = true;
  const address = whiteLabel.address;
  if (address) {
    const validAddress = address.streetLines.length > 0 && address.city && address.postalCode && address.countryCode;
    if (validAddress) showError = false;
  }
  return showError;
}

export const onOriginCountyChange = (event: any, dispatch: any) => {
  dispatch(bookingActions.updateShipperDetail({
    field: 'countyStateProvince',
    value: event.value
  }))
}