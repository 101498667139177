import React, { useState, ChangeEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './Auth.scss';
import {customerActions, customerDetailsSelector, login, User} from '../features/customerSlice';
import {useAppDispatch, useAppSelector} from "../app/hooks";
import { bookingActions } from "../features/bookingSlice";
import { appDataActions, appDataSelector } from "../features/appDataSlice";
import { checkBrowser, isMac, isSafariBrowser } from "./BrowserCheck";
import { isValidEmail } from "../utilities/ValidationUtilities";
import {
  AMIFormElement,
  AMIInput,
  AMIButton,
  AMIAlert
} from "../ui-components/UiComponents";
import { handleRules, RuleSet } from "../utilities/RulesEngineUtilities";
import { getThemeData } from "../utilities/APIUtilities";
import { defaultColors, setDocumentColors } from "../pages/account-page/white-label/WhiteLabelUtils";
import { retrieveImage } from "../utilities/ContentRetrieverUtilities";
import { encodeJSONImage } from "../utilities/HelperUtilities";

const Auth: React.FC = () => {

  const { apiConfig } = useAppSelector(appDataSelector);

  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [showForgotText, setShowForgotText] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [wrongDetailsCount, setWrongDetailsCount] = useState(0);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isValidBrowser, setIsValidBrowser] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [showErrors, setShowErrors] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const customerDetails = useAppSelector(customerDetailsSelector);
  const isWlDomainOrigin = window.location.href.includes('expressfr8');

  useEffect(() => {
    if (isWlDomainOrigin) setTempWLColors();
  }, [])

  const setTempWLColors = () => {
    document.documentElement.style.setProperty('--primary', "#000000");
  }

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.currentTarget.value.trim();
    if (text && !isValidEmail(text)) setValidEmail(false);
    else setValidEmail(true);
    setUsername(text);
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  }

  const setWlTheme = async(parentPartyId: string) => {
    try {
      const config = {
        ...apiConfig,
        headers: {
          ...apiConfig.headers,
          authorization: `Bearer ${customerDetails.auth.accessToken}`,
          'Master-Token': customerDetails.auth.masterToken
        }
      }
      const result = await getThemeData(config) as any;
      if (result) {
        const theme = result.data.Item;
        const logoImage = await retrieveImage(customerDetails, theme.logoUuid) as any;
        const bannerImage = await retrieveImage(customerDetails, theme.bannerImageUuid);

        if (logoImage) {
          const encodedImage = encodeJSONImage(logoImage);
          dispatch(appDataActions.setWlLogoImage(encodedImage));
        }

        if (bannerImage) {
          const encodedImage = encodeJSONImage(bannerImage);
          dispatch(appDataActions.setWlBannerImage(encodedImage));
        }
        dispatch(appDataActions.setCustomColors(theme.colourScheme))
        setDocumentColors(theme.colourScheme);
      } else {
        setDocumentColors(defaultColors);
      }
    } catch (error) {
      console.error(error);
      setDocumentColors(defaultColors)
    }
  }

  const handleSignIn = async() => {
    event?.preventDefault();
    setShowErrors(true);
    setIsSigningIn(true);
    setWrongDetailsCount(prevState => (prevState + 1));
    const lowerCaseUsername = username.toLowerCase();
    const user: User = {
      username: lowerCaseUsername,
      password: password
    };

    await dispatch(login(user))
      .then(async (result:any) => {
        const customerData = result['payload'];
        await handleRules(RuleSet.ON_START, customerData, {}, dispatch)
          .catch((error: any) => console.error(error.message));
      }).catch((error:any) => console.error(error.message));

    dispatch(customerActions.updateField({
      field: 'logonEmail',
      value: lowerCaseUsername
    }))

  };

  const redirectToResetPassword = () => {
    window.open(
      process.env.REACT_APP_NEBE_RESET_PASSWORD_URL,
      '_blank'
    );
  }

  const showErrorMessage = () => {
    if (errorMessage && !isValidBrowser) {
      if (isMac() && isSafariBrowser()) {
        return 'Please update Safari to the latest version. You are using a version of Safari that is not supported.'
      } else {
        return (
          <span
            className="alert__text"
            style={{marginTop: '140px', paddingTop: '20px'}}
          >
            Your browser version is not supported, please use a supported browser from the list below:
            <ul style={{marginTop: 0, marginBottom: 0}}>
              <li>
                <a href='https://www.google.com/chrome' target="_blank" rel="noreferrer">Google Chrome</a>
              </li>
              <li>
                <a href='https://www.microsoft.com/en-us/edge?r=1#evergreen' target="_blank" rel="noreferrer">Edge</a>
              </li>
              <li>
                <a href='https://www.opera.com/download' target="_blank" rel="noreferrer">Opera</a>
              </li>
              <li>
                <a href='https://www.mozilla.org/firefox/browsers' target="_blank"  rel="noreferrer">Firefox</a>
              </li>
            </ul>
          </span>
        )
      }
    } else if (errorMessage) {
      return errorMessage
    } else if (!isWlDomainOrigin) {
      return <div>{errorMessage} Click <a
        href="https://app.customer-portal.blujaysolutions.net/authenticate/#/AMI/login"
        target="_blank"
        rel="noreferrer">here</a> to reset login credentials.
      </div>;
    }
  }

  useEffect(() => {
    const validBrowser = checkBrowser();
    setIsValidBrowser(validBrowser);
    dispatch(customerActions.logOut());
    dispatch(bookingActions.resetState());
    dispatch(appDataActions.resetState());
  }, [])

  useEffect(() => {
    setErrorMessage('');
    const validBrowser = checkBrowser();
    if (!validBrowser) {
      setErrorMessage("Your browser version isn’t supported, please use a supported browser from the list below:");
    }
  }, [isValidBrowser])

  useEffect(() => {
    handleLoginChecks()
      .catch((error: any) => console.log(error.message));
  }, [customerDetails]);

  const handleLoginChecks = async() => {
    setErrorMessage('');
    if (showErrors) {
      let canContinue = true;
      if (
        customerDetails.loginErrorStatus === 401
        || (isWlDomainOrigin && !customerDetails.creditCheck.whiteLabel)
        || (!isWlDomainOrigin && customerDetails.creditCheck.whiteLabel && customerDetails.creditCheck.whiteLabel.parentPartyId)
        || (isWlDomainOrigin && customerDetails.creditCheck.whiteLabel && customerDetails.creditCheck.whiteLabel.isParent)
      ) {
        canContinue = false;
        isWlDomainOrigin
          ? setErrorMessage('Your username and / or password are incorrect, please try again or contact customer services to reset.')
          : setErrorMessage('Your username and/or password are incorrect, please try again.')
      } else if (
        customerDetails.loginErrorStatus
        || !customerDetails.auth.accessToken
        && wrongDetailsCount > 0
        ) {
        canContinue = false;
        isWlDomainOrigin
          ? setErrorMessage('There was a login error, please try again')
          : setErrorMessage('There was a login error, please try again or contact Customer Services directly.');
      }

      if (isWlDomainOrigin && customerDetails.creditCheck.whiteLabel && customerDetails.creditCheck.whiteLabel.parentPartyId) {
        await setWlTheme(customerDetails.creditCheck.whiteLabel.parentPartyId);
      }

      setIsSigningIn(false);

      if (canContinue && customerDetails.auth.accessToken) {
        history.push('/single-booking');
        return;
      }

      document.getElementsByTagName('input')[1].value = "";
      setPassword('');

      if (wrongDetailsCount > 2 && !isWlDomainOrigin) {
        redirectToResetPassword();
        setWrongDetailsCount(0);
      }
    }
  }

  const onUsernameBlur = () => {
    setShowErrors(true);
  }

  return (
    <div className="auth-page">

      <form className="auth-container">

        {!isWlDomainOrigin && <img className="auth-container__logo" src={"images/logo.png"} alt="Logo"/>}
        {isWlDomainOrigin && <img className="auth-container__logo" src={"images/express-fr8-logo.png"} alt="Logo"/>}

        <AMIFormElement
          label="Username"
          errorMessage={showErrors && !validEmail ? "Please enter a valid email address as your username" : ""}
          style={{width: "100%"}}
        >
          <AMIInput
            style={{width: '100%'}}
            name="username"
            placeholder="Username..."
            size="large"
            value={username}
            onChange={handleUsernameChange}
            onBlur={onUsernameBlur}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Password"
          style={{width: "100%"}}
        >
          <AMIInput
            id="password"
            style={{width: '100%', marginBottom: "12px"}}
            name="password"
            placeholder="Password..."
            size="large"
            type="password"
            onChange={handlePasswordChange}
          />
        </AMIFormElement>

        {errorMessage
        &&  <AMIAlert
              variant="error"
              style={{marginBottom: "24px", width: "100%"}}
            >{showErrorMessage()}
            </AMIAlert>
        }

        <AMIButton
          variant="primary"
          size="large"
          type="submit"
          style={{width: "100%"}}
          isSpinning={isSigningIn}
          disabled={!isValidBrowser || (showErrors && !validEmail)}
          onClick={handleSignIn}
        >Sign In</AMIButton>

        {!isWlDomainOrigin && (
          <p
            className="auth-container__forgot-title"
            style={showForgotText ? {color: 'var(--primary)'} : {color: 'var(--text)'}}
            onClick={() => setShowForgotText(!showForgotText)}
          >
            Forgot your username / password?
          </p>
        )}

        {showForgotText
        && <p className="auth-container__forgot-text">If you have forgotten your password then please follow this <a href={process.env.REACT_APP_NEBE_RESET_PASSWORD_URL} target="_blank" rel="noreferrer">link</a>. This will take you to the AMI portal where you can set up a new password.</p>
        }
      </form>

    </div>
  )
};

export default Auth;