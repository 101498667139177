import React, {useEffect, useState} from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import { AMICheckbox, AMIFormElement, AMIInput } from '../../../../../ui-components/UiComponents';
import { AppData, appDataActions } from '../../../../../features/appDataSlice';
import { Booking, bookingActions } from '../../../../../features/bookingSlice';
import UploadDocument from '../upload-document/UploadDocument';
import {getUKDeclarationNotesValue} from "../../../../../utilities/HelperUtilities";
import { getRule } from "../../../../../utilities/RulesEngineUtilities";

const InvoiceCardGenerateInvoice: React.FC<{
  invoiceData: any,
  booking: Booking,
  showErrors: boolean,
  errHandler: any,
  customRules: any,
  appData: AppData
}> = ({
  invoiceData,
  booking,
  showErrors,
  errHandler,
  customRules,
  appData
}) => {

  const dispatch = useAppDispatch();

  const [fileFlash, setFileFlash] = useState(false);

  const toggleUKDeclarationStatement = () => {
    dispatch(bookingActions.setDeclarationStatementRequestedValue())
    if (booking.customsDetails.invoiceDetails.declarationStatement === '') {
      dispatch(bookingActions.setInvoiceDeclarationStatement(getUKDeclarationNotesValue(booking)));
    } else {
      dispatch(bookingActions.setInvoiceDeclarationStatement(''))
      dispatch(bookingActions.setDeclarationStatementRequestedType(''));
    }
  }

  const [ukDeclarationStatementRequired, setUKDeclarationStatementRequired] = useState(false)

  useEffect(() => {
    setUKDeclarationStatementRequired(getRule(appData.customRules, 'UKPreferentialOriginStatement'));
  }, [customRules])

  return (
    <div className="generate-invoice-online-container">
      <div className="generate-invoice-form">
        <AMIFormElement
          label="Name"
          errorMessage={
            showErrors
            && !errHandler.invoice.name.criteria
            ? errHandler.invoice.name.message
            : ''
          }
        >
          <AMIInput
            name="invoice-name"
            placeholder="Required"
            size="large"
            value={invoiceData.name}
            onChange={(event: any) => dispatch(appDataActions.updateInvoiceDetail({
              field: 'name',
              value: event.target.value
            }))}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Position"
          errorMessage={
            showErrors
            && !errHandler.invoice.title.criteria
            ? errHandler.invoice.title.message
            : ''
          }
        >
          <AMIInput
            name="invoice-position"
            placeholder="Required"
            size="large"
            value={invoiceData.title}
            onChange={(event: any) => dispatch(appDataActions.updateInvoiceDetail({
              field: 'position',
              value: event.target.value
            }))}
          />
        </AMIFormElement>
      </div>

      {ukDeclarationStatementRequired && (
        <AMIFormElement>
          <AMICheckbox
            checked={booking.customsDetails.invoiceDetails.declarationStatementRequested.value}
            text={'Would you like to add a UK Preferential Origin Statement to your generated invoice?'}
            onChange={toggleUKDeclarationStatement}
            style={{marginBottom: "1rem", fontWeight: "bold"}}
          />
        </AMIFormElement>
        )
      }

      <p>Please select your document type and then either drag and drop a document into the box below or click on Upload to select a file.</p>
      <br />
      <p><strong>One of these documents must be a signature image</strong></p>
      <br />
      <p>Document must be of type PNG, GIF, TIFF or JPEG and have a maximum file size of 2MB</p>

      <UploadDocument option="GENERATE" />

      {booking.uploadedSignature?.signatureImage
      && <p
        className="uploaded-file-name"
        style={
          fileFlash
          ? {animation: 'flash .2s infinite'}
          : {animation: 'none'}
        }
      ><strong>{booking.uploadedSignature?.signatureRef}</strong> has been uploaded</p>}

      {showErrors
        && !errHandler.invoice.uploadedSignature.criteria
        && <p
          style={{color: 'var(--error)'}}
      >{errHandler.invoice.uploadedSignature.message}</p>}

    </div>
  )
}

export default InvoiceCardGenerateInvoice;