import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIAlert, AMIPopConfirm, Icon } from '../../../../ui-components/UiComponents';
import { bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import './QuoteSummary.scss';
import { onTradelaneSwitch, retrieveQuotes, storeQuotes } from '../../home-page/utils';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';

const QuoteSummary = () => {

  const booking = useAppSelector(bookingSelector);
  const dispatch = useAppDispatch();
  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const isReQuoting = useAppSelector(appDataSelector).isReQuoting;

  const [currencyError, setCurrencyError] = useState(false);
  const [weight, setWeight] = useState(0);
  const [showReQuoteConfirm, setShowReQuoteConfirm] = useState(false);

  const kgLb = booking.quotes[0].weight.actual.unit === 'LB' ? 'lb' : 'kg';

  useEffect(() => {
    getWeight();
  }, [])

  const getWeight = () => {
    let weight = 0;
    for (const piece of booking.pieces) {
      weight += +piece.weight;
    }
    setWeight(weight);
  }

  const onTradelaneSwitchClick = async() => {
    setShowReQuoteConfirm(false);
    dispatch(appDataActions.setIsReQuoting(true));
    onTradelaneSwitch(booking, dispatch, appData.apiConfig);
  }

  const getNewQuotes = async() => {
    const searchResult = await retrieveQuotes(booking, dispatch, bookingActions, customer, setCurrencyError, appData, booking.shipperDetails.countyStateProvince);

    if (typeof (searchResult) === 'string') {
      dispatch(appDataActions.setIsReQuoting(false));
      return;
    } else storeQuotes(searchResult, dispatch);

    dispatch(appDataActions.setIsReQuoting(false));
  }

  useEffect(() => {
    getNewQuotes();
  }, [booking.destinationPostalCode])

  const popConfirmTarget = document.getElementsByClassName('quote-summary__tradelane-switch')[0] as HTMLElement;

  return (
    <div className="quote-summary vertical-card">
      <div
        className="quote-summary__tradelane-switch"
        onClick={() => setShowReQuoteConfirm(true)}
      >
        <Icon
          name="SwitchLeftRight"
          color="var(--secondary)"
          style={{
            width: "24px",
            height: "24px"
          }}
        />
      </div>

      <AMIPopConfirm
        parentElement={popConfirmTarget}
        isVisible={showReQuoteConfirm}
        position="bottom"
        onCancel={() => setShowReQuoteConfirm(false)}
        onConfirm={onTradelaneSwitchClick}
      >
        <p><strong>Swap Origin/Destination</strong></p>
        <p>Do you wish to perform a new search with the origin/destination swapped? <strong>{booking.destination.title}</strong> to <strong>{booking.origin.title}</strong></p>
      </AMIPopConfirm>

      <div className="quote-summary__to-from-container">
        <span>{booking.origin.title}</span>
        <Icon
          name="Parcel"
          color="var(--secondary)"
          style={{
            width: '24px',
            height: '24px'
          }}
        />
        <span>{booking.destination.title}</span>
      </div>

      {+booking.totalShipmentValue !== 0 && <p>Shipment Value: <strong>{booking.preferredCurrency.symbol}{booking.totalShipmentValue}</strong></p>}
      <p>Weight: <strong>{weight}{kgLb}</strong></p>

      {currencyError && <AMIAlert variant="error">Sorry, we could not return an exchange rate for the selected currency, please try again or choose another currency.</AMIAlert>}
    </div>
  )
}

export default QuoteSummary;