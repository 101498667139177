import React, { useEffect, useState } from 'react';
import './CollectionDetails.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMICheckbox, AMIFormElement, AMIInput, AMISelect } from '../../../../ui-components/UiComponents';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import { PackageLocation } from '../../../../utilities/APIUtilities';
import { getCarrierName, selectTitleFormatter, ServiceType, trimTelephone } from '../../../../utilities/HelperUtilities';
import { checkCollectionTimesAreValid, times } from './utils';
import SavedAddressSelect, { DetailSection } from '../../../../components/saved-address-select/SavedAddressSelect';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { compareAddresses, getAndSetWLAddress } from '../utilities';
import { getRule } from "../../../../utilities/RulesEngineUtilities";

const CollectionDetails: React.FC<{
  errHandler: any;
  customRules: any;
}> = ({
  errHandler,
  customRules
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const showErrors = appData.showDetailsPageErrors;
  const branchId = customer.creditCheck.branchId;
  const timeWindow = customer.countryOfResidence.value === 'ZA' ? 8 : 4;

  const [collectionToTimes, setCollectionToTimes] = useState(times);
  const [savedAddressSnapshot, setSavedAddressSnapshot] = useState<any>();
  const [disableSaveAddressCheckbox, setDisableSavedAddressCheckbox] = useState(false);

  useEffect(() => {
    setDisableSavedAddressCheckbox(compareAddresses(
      booking.collectionDetails,
      booking.origin,
      customer.creditCheck.branchId,
      savedAddressSnapshot
    ))
  }, [booking.collectionDetails]);

  useEffect(() => {
    getAndSetWLAddress('ORIGIN', customer, booking, dispatch);
  }, [])

  const changeCollectionDetails = (field: string, event: any) => {
    let value = event.value;
    if (field === 'telephoneNumber') {
      value = trimTelephone(value);
    }
    dispatch(bookingActions.updateCollectionDetail({
      field,
      value: value
    }))
  }

  const resetPickUpTo = (pickUpToIndex: number) => {
    const pickUpToReduxIndex = times.findIndex((time) => {
      return time.value === booking.collectionDetails.pickUpTo;
    })
    if (pickUpToIndex > pickUpToReduxIndex) {
      dispatch(bookingActions.updateCollectionDetail({field: 'pickUpTo', value: ''}))
    }
  }

  const changePickUpFromTime = (field: string, event: any) => {
    changeCollectionDetails(field, event);

    const pickUpFromIndex = times.findIndex((time) => {
      return event.value === time.value;
    })
    const pickUpToIndex = pickUpFromIndex + timeWindow;

    const newTimes = times.slice(pickUpToIndex);
    setCollectionToTimes(newTimes);

    resetPickUpTo(pickUpToIndex);
  }

  useEffect(() => {
    if (
      getCarrierName(booking.selectedQuote.quoteId) === 'dhl'
      && booking.selectedQuote.serviceType === ServiceType.COLLECTED
    ) {
      dispatch(appDataActions.setCollectionTimesError(checkCollectionTimesAreValid(booking, timeWindow)));
    } else {
      dispatch(appDataActions.setCollectionTimesError(false));
    }
  }, [booking.collectionDetails.pickUpFrom, booking.collectionDetails.pickUpTo])

  useEffect(() => {
    const pickUpToIndex = times.findIndex((time) => {
      return booking.collectionDetails.pickUpFrom === time.value
    }) + timeWindow;
    const newTimes = times.slice(pickUpToIndex);
    setCollectionToTimes(newTimes);
  }, [])

  const changePostalCode = (event: any) => {
    dispatch(bookingActions.updateField({
      field: 'originPostalCode',
      value: event.target.value
    }))
  }

  return (
    <div
      className="collection-details horizontal-card"
      id="collection-details"
    >

      <p className="horizontal-card__title">Collection Details</p>
      <p className="details-page__address-type">
        {booking.originResidential ? "Residential" : "Commercial"}
      </p>

      <AMIFormElement
        label="Pick up from"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.pickUpFrom.criteria
          ? errHandler.collectionDetails.pickUpFrom.message
          : ''
        }
      >
        <AMISelect
          name="pickUpFrom"
          placeholder="Required"
          size="large"
          options={times.slice(0, -timeWindow)}
          defaultValue={{
            title: booking.collectionDetails.pickUpFrom.slice(0,5),
            value: booking.collectionDetails.pickUpFrom
          }}
          hideClearButton
          onChange={changePickUpFromTime.bind(null, 'pickUpFrom')}
        />

        {/*todo: REMOVE once we are getting current time at selected origin*/}
        <span style={{fontSize: 14, color: "var(--primary)"}}>
          Must be minimum 30 mins later than local time at collection address.
        </span>
        {/*todo*/}

      </AMIFormElement>

      <AMIFormElement
        label="Pick up to"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.pickUpTo.criteria
          ? errHandler.collectionDetails.pickUpTo.message
          : ''
        }
      >
        <AMISelect
          name="pickUpTo"
          placeholder="Required"
          size="large"
          options={collectionToTimes}
          defaultValue={{
            title: booking.collectionDetails.pickUpTo?.slice(0,5),
            value: booking.collectionDetails.pickUpTo
          }}
          hideClearButton
          onChange={changeCollectionDetails.bind(null, 'pickUpTo')}
        />
      </AMIFormElement>

      {appData.collectionTimesError && (
          <p className="pick-up-time-error-message">Collection times provided by the carrier are between {booking.selectedQuote.pickupEarliestTime?.slice(0,5)} and {booking.selectedQuote.pickupLatestTime?.slice(0,5)}, please ensure you have selected at least a 1 hour window within this time frame.</p>
        )
      }

      <SavedAddressSelect
        section={DetailSection.COLLECTION_DETAILS}
        filters={['cityTown', 'postalCode']}
        onChange={(address) => setSavedAddressSnapshot(address)}
      ></SavedAddressSelect>

      <AMIFormElement
        label="Full Contact Name"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.contactName.criteria
          ? errHandler.collectionDetails.contactName.message
          : ''
        }
      >
        <AMIInput
          name="collectionContactName"
          placeholder="Required"
          size="large"
          value={booking.collectionDetails.contactName}
          onChange={(event) => changeCollectionDetails('contactName', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Company Name"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.companyName.criteria
          ? errHandler.collectionDetails.companyName.message
          : ''
        }
      >
        <AMIInput
          name="collectionCompanyName"
          placeholder="Required"
          size="large"
          value={booking.collectionDetails.companyName}
          onChange={(event) => changeCollectionDetails('companyName', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Package Location"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.packageLocation.criteria
          ? errHandler.collectionDetails.packageLocation.message
          : ''
        }
      >
        <AMISelect
          name="collectionPackageLocation"
          placeholder="Required"
          size="large"
          defaultValue={{
            title: selectTitleFormatter(booking.collectionDetails.packageLocation),
            value: booking.collectionDetails.packageLocation
          }}
          options={[
            {title: 'Front', value: PackageLocation.FRONT},
            {title: 'None', value: PackageLocation.NONE},
            {title: 'Rear', value: PackageLocation.REAR},
            {title: 'Side', value: PackageLocation.SIDE}
          ]}
          hideClearButton
          onChange={changeCollectionDetails.bind(null, 'packageLocation')}
        />

      </AMIFormElement>

      <div className="spacer"></div>

      <AMIFormElement
        label="Country"
        className="details-page__country"
      >
        <AMIInput
          name="collectionCountry"
          placeholder="Required"
          size="large"
          disabled
          value={booking.origin.title}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 1"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.addressLine1.criteria
          ? errHandler.collectionDetails.addressLine1.message
          : ''
        }
      >
        <AMIInput
          name="collectionAddressLine1"
          placeholder="Required"
          size="large"
          value={booking.collectionDetails.addressLine1}
          onChange={(event) => changeCollectionDetails('addressLine1', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 2"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.addressLine2.criteria
          ? errHandler.collectionDetails.addressLine2.message
          : ''
        }
      >
        <AMIInput
          name="collectionAddressLine2"
          size="large"
          value={booking.collectionDetails.addressLine2}
          onChange={(event) => changeCollectionDetails('addressLine2', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 3"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.addressLine3.criteria
          ? errHandler.collectionDetails.addressLine3.message
          : ''
        }
      >
        <AMIInput
          name="collectionAddressLine3"
          size="large"
          value={booking.collectionDetails.addressLine3}
          onChange={(event) => changeCollectionDetails('addressLine3', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="City/Town"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.cityTown.criteria
          ? errHandler.collectionDetails.cityTown.message
          : ''
        }
      >
        <AMIInput
          name="collectionCityTown"
          placeholder="Required"
          size="large"
          value={booking.originCityTown}
          disabled
        />
      </AMIFormElement>

      {appData.originCounties.length === 0
        && !getRule(appData.customRules, 'upsCollCountyStateHidden')
        && (
          <AMIFormElement
            label="County/State/Province"
            errorMessage={
              showErrors
              && !errHandler.collectionDetails.countyStateProvince.criteria
                ? errHandler.collectionDetails.countyStateProvince.message
                : ''
            }
          >
            <AMIInput
              name="collectionCountyStateProvince"
              placeholder={getRule(appData.customRules, 'collCountyStateRequired') ? "Required" : ""}
              size="large"
  
              value={booking.collectionDetails.countyStateProvince}
              onChange={(event) => changeCollectionDetails('countyStateProvince', event.target)}
            />
          </AMIFormElement>
        )}
      {appData.originCounties.length > 0
        && !getRule(appData.customRules, 'upsCollCountyStateHidden')
        && (
          <AMIFormElement
            label="County/State/Province"
            errorMessage={
              showErrors
              && !errHandler.collectionDetails.countyStateProvince.criteria
                ? errHandler.collectionDetails.countyStateProvince.message
                : ''
            }
          >
            <AMISelect
              name="collectionCountyStateProvince"
              placeholder={getRule(appData.customRules, 'collCountyStateRequired') ? "Required" : ""}
              size="large"
              isSearchable

              options={appData.originCounties}
              onChange={changeCollectionDetails.bind(null, 'countyStateProvince')}
              defaultValue={appData.originCounties.find(item => {
                return item.value === booking.collectionDetails.countyStateProvince
              })}
            />
          </AMIFormElement>
        )}

      <AMIFormElement label="Postal/Zip Code">
        <AMIInput
          name="collectionPostalCode"
          placeholder={appData.originPostalCodeRegex ? "Required" : ""}
          size="large"
          disabled={!!appData.originPostalCodeRegex}
          value={booking.originPostalCode}
          onChange={(event) => changePostalCode(event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Telephone Number"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.telephoneNumber.criteria
          ? errHandler.collectionDetails.telephoneNumber.message
          : ''
        }
      >
        <AMIInput
          name="collectionTelephoneNumber"
          placeholder="Required"
          size="large"
          value={booking.collectionDetails.telephoneNumber}
          onChange={(event) => changeCollectionDetails('telephoneNumber', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Email"
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.email.criteria
          ? errHandler.collectionDetails.email.message
          : ''
        }
      >
        <AMIInput
          name="collectionEmail"
          placeholder="Required"
          size="large"
          type="email"
          value={booking.collectionDetails.email}
          onChange={(event) => changeCollectionDetails('email', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Additional Information / Special Instructions"
        style={{gridColumn: '1 / 3'}}
        errorMessage={
          showErrors
          && !errHandler.collectionDetails.additionalInfo.criteria
          ? errHandler.collectionDetails.additionalInfo.message
          : ''
        }
      >
        <AMIInput
          name="collectionAdditionalInfo"
          size="large"
          value={booking.collectionDetails.additionalInfo}
          onChange={(event) => changeCollectionDetails('additionalInfo', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement className="save-address__checkbox">
        <AMICheckbox
          text=""
          disabled={disableSaveAddressCheckbox || !branchId}
          checked={appData.saveCollectionAddress}
          onChange={() => dispatch(appDataActions.setCollectionSaveAddressCheckbox())}
        >
          {branchId && <p>Save this address for future use?</p>}
          {!branchId && <p>Save this address for future use? (Please contact <a href="/contact" target="_blank" rel="noreferrer">customer services</a> to enable address book features)</p>}
        </AMICheckbox>
      </AMIFormElement>

    </div>
  )
}

export default CollectionDetails;